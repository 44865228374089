import * as React from "react";
import styled from "styled-components";
import { graphql, navigate } from "gatsby";
import { PhotosPageQuery } from "../../types/graphql-types";
import Layout from "../components/Layout";
import { theme } from "../components/theme";
import { Meta } from "../components/Meta";
import { Helmet } from "react-helmet";

interface PhotosPageProps {
  data: PhotosPageQuery;
}

const PhotoAlbums: React.FC<PhotosPageProps> = ({ data }) => {
  const handlerOnClickCard = (to: string) => {
    navigate(to);
  };

  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>kame's blog - PhotoAlbums</title>
      </Helmet>

      <Container>
        <h1 style={{ width: "100%", textAlign: "center" }}>Photos</h1>

        <PhotoAlbumContainer>
          {data.allContentfulPhotoAlbum.edges.map((item) => {
            return (
              <PhotoAlbumCard
                key={item.node.contentful_id}
                onClick={() =>
                  handlerOnClickCard(`/photo-album/${item.node.slug}`)
                }
              >
                <img src={item.node.heroImage[0].secure_url} />
                <h4>{item.node.title}</h4>
              </PhotoAlbumCard>
            );
          })}
        </PhotoAlbumContainer>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query PhotosPage {
    allContentfulPhotoAlbum {
      edges {
        node {
          contentful_id
          heroImage {
            url
            secure_url
          }
          title
          slug
          updatedAt(formatString: "YYYY年MM月DD日")
        }
      }
    }
  }
`;

export default PhotoAlbums;

const Container = styled.div`
  margin: 0 auto;

  width: 100vw;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: ${theme.width.body};
    padding: 0;
  }
`;

const PhotoAlbumContainer = styled.div`
  padding: 20px 12px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-around;
`;

const PhotoAlbumCard = styled.div`
  margin: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 50%;
  }

  h4 {
    width: 100%;
    text-align: center;
  }
`;
